<template>
  <div>
    <SlListView
      ref="listView"
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
      :pageSizes="pageSizes"
    >
      <div slot="search">
        <!-- 搜索区域search包含搜索和重置按钮 -->
        <SlSearchForm
          ref="searchForm"
          v-model="formQuery"
          :items="searchItems"
          :loading="loading"
          @reset="reset"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>
      </div>
      <!-- tab切换 -->
      <SlSwitchNav
        align="right"
        :navs="switchNavs"
        :default-active="switchActiveIndex"
        @select="switchNav"
      >
        <template v-slot:left>
          <span class="pdl-2rem">
            <SlButton
              class="mr-8px"
              type="primary"
              boxShadow="primary"
              :loading="loading"
              :disabled="!canExcute"
              @click="openDeliverDialog"
            >发货</SlButton>
            <SlButton
              class="mr-8px"
              type="primary"
              boxShadow="primary"
              :loading="loading"
              :disabled="!canExcute"
              @click="openPriceAuditDialog"
            >成本复核</SlButton>
            <SlButton type="primary" boxShadow="primary" :loading="loading" @click="exportPdf">导出</SlButton>
          </span>
        </template>
        <template v-slot:custom="{tab}">{{tab.tabName}}({{tab.count}})</template>
      </SlSwitchNav>
      <!-- 表格区域包含分页 -->
      <SlCardTable
        ref="cardTable"
        :data="tableData"
        :columns="columns"
        childName="awaitShipmentItemVoList"
      >
        <template #header="{row}">
          <el-tag
            class="mr-8px align-center"
            effect="plain"
            style="width: 54px;"
            :class="{'purple-tag': row.orderProcessType === 1, 'success-tag': row.orderProcessType === 2}"
          >{{row.orderProcessTypeName}}</el-tag>
          <el-tag v-if="row.fastOrderFlag" class="mr-8px float-right" type="danger">快返单</el-tag>
          <el-tag v-if="row.needRfid" class="mr-8px float-right">RFID</el-tag>
          <ul v-if="columns.length > 0" class="default-header-content">
            <li class="mr-24px" v-for="propItem in columns" :key="propItem.prop">
              <span class="header-prop-label mr-8px">{{propItem.label}}:</span>
              <span class="header-prop-value">{{row[propItem.prop]}}</span>
            </li>
          </ul>
        </template>
        <template #body="{row:cardRow}">
          <SlTable
            ref="table"
            v-model="selectionsTree[cardRow.id]"
            align="left"
            :border="false"
            :tableData="cardRow['awaitShipmentItemVoList']"
            :columns="childColumns"
            :operate="false"
            :tooltip="false"
            :isEmbedTable="true"
            rowKey="id"
          >
            <template #warehouseName="{ row }">
              <p>{{row.warehouseName}}</p>
              <p v-if="row.orderProcessType === 1">
                <el-button
                  type="text"
                  @click="showSupplierDetail(row)"
                >{{row.processPlantSupplierName}}</el-button>
              </p>
            </template>
          </SlTable>
        </template>
      </SlCardTable>
    </SlListView>
    <!-- 发货对话框 -->
    <DeliveryDialog ref="deliveryDialog" @submit="gotoPage(page.pageSize)"></DeliveryDialog>
    <SupplierDialog v-bind.sync="supplier" />
    <!-- 成本复核 -->
    <PriceReviewDialog ref="priceReview" @submit="priceReviewSubmit"></PriceReviewDialog>
    <!-- 发货、成本复核按钮操作提示弹窗 -->
    <SlDialog title="提示" :visible.sync="btnMessageVisible">
      {{btnMessageTip}}
      <template v-slot:bottom>
        <el-button @click="btnMessageVisible = false">关闭</el-button>
      </template>
    </SlDialog>
  </div>
</template>

<script>
import UserApi from '@api/user'
import OemGoodsAPI from '@api/oemGoods'
import CommonUrl from '@api/url.js'
import PriceReviewDialog from './components/PriceReviewDialog'
import DeliveryDialog from './pendingDeliverOrderList/DeliveryDialog.vue'
import SupplierDialog from './pendingOrderList/SupplierDialog'

export default {
  name: 'PendingOrderList',
  components: {
    DeliveryDialog,
    SupplierDialog,
    PriceReviewDialog
  },
  data () {
    return {
      loading: false,
      btnMessageVisible: false,
      tableData: [],
      selectionsTree: {},
      switchNavs: [],
      switchActiveIndex: '0',
      extraQuery: {
        status: 1
      },
      formQuery: {},
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      pageSizes: [10, 20, 50],
      searchItems: [
        {
          type: 'batch-input',
          label: '生产订单号',
          name: 'purchaseOrderNumber'
        },
        {
          type: 'batch-input',
          label: 'SKU编码',
          name: 'sku'
        },
        {
          type: 'batch-input',
          label: 'UPC编码',
          name: 'upcs'
        },
        {
          type: 'input',
          label: '商品名称',
          name: 'commodityName'
        },
        {
          type: 'date',
          label: '下单时间',
          name: 'orderTimes',
          data: {
            datetype: 'daterange',
            isBlock: true
          }
        },
        {
          type: 'single-select',
          label: '快返单',
          name: 'fastOrderFlag',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'YES_NO' }
          }
        },
        {
          type: 'single-select',
          label: '订单类型',
          name: 'orderProcessType',
          data: {
            remoteUrl: CommonUrl.dictUrl,
            params: { dataCode: 'ORDER_PROCESS_TYPE' }
          }
        }
      ],
      columns: [
        {
          prop: 'purchaseOrderNumber',
          label: '生产订单号'
        },
        {
          prop: 'orderTime',
          label: '下单时间'
        }
      ],
      childColumns: [
        {
          prop: 'skuCode',
          label: 'SKU编码'
        },
        {
          prop: 'upc',
          label: 'UPC编码'
        },
        {
          prop: 'skuImage',
          label: '商品图片',
          isImg: true,
          data: {
            imgSize: '8rem'
          }
        },
        {
          prop: 'name',
          label: '商品名称'
        },
        {
          prop: 'attributesName',
          label: '销售属性'
        },
        {
          prop: 'orderTypeName',
          label: '首/返单类型'
        },
        {
          prop: 'basePlateSkuCode',
          label: '底版SKU'
        },
        {
          prop: 'printedPattern',
          label: '花型号'
        },
        {
          prop: 'realPrice',
          label: '单价'
        },
        {
          prop: 'requireQuantity',
          label: '订单数量'
        },
        {
          prop: 'deliveryQuantity',
          label: '实际发货数量'
        },
        {
          prop: 'warehouseName',
          label: '到货仓库'
        },
        {
          prop: 'claimArrivalDate',
          label: '应交货时间'
        }
      ],
      supplier: {
        data: {},
        visible: false
      },
      btnMessageTip: ''
    }
  },
  computed: {
    canExcute () {
      return Object.values(this.selectionsTree).some(selections => selections.length)
    }
  },
  mounted () { },
  methods: {
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      delete params.status // 列表接口不要这个参数
      this.loading = true
      OemGoodsAPI.getAwaitShipmentsList(params).then(res => {
        let { success, data = {} } = res
        if (success) {
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
          this.selectionsTree = {}
          this.getSwitchNavs()
          this.tableData.forEach(row => {
            this.formatChildRow(row)
            this.$set(this.selectionsTree, row.id, [])
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    reset () {
      this.switchActiveIndex = '0'
      this.gotoPage(this.page.pageSize)
    },
    getSwitchNavs () {
      OemGoodsAPI.getPendingDeliverStatic(this.getQureyParams()).then(res => {
        let { data = [] } = res
        this.switchNavs = data || []
      })
    },
    switchNav (index) {
      this.switchActiveIndex = index
      this.gotoPage(this.page.pageSize)
    },
    openDeliverDialog () {
      let { deliveryParams: params, deliveriedOrders, isWaitAuditPriceRecheckOrders } = this.prevDataHandler()
      if (this.btnHandleValidate('doDelivery', isWaitAuditPriceRecheckOrders, deliveriedOrders)) {
        this.$refs.deliveryDialog.openDialog({ params })
      }
    },
    generateParams (pageSize, pageIndex) {
      return {
        ...this.getQureyParams(),
        overdueDays: this.switchActiveIndex,
        pageIndex,
        pageSize
      }
    },
    getQureyParams () {
      let { orderTimes = [], ...orther } = this.formQuery
      return {
        ...orther,
        ...this.extraQuery,
        orderTimeStart: orderTimes && orderTimes[0] ? orderTimes[0] : '',
        orderTimeEnd: orderTimes && orderTimes[1] ? orderTimes[1] : ''
      }
    },
    showSupplierDetail (row) {
      const id = row.processPlantSupplierId
      UserApi.supplierShippingAddress(id).then(res => {
        if (res.success) {
          this.supplier = {
            data: res.data || {},
            visible: true
          }
        }
      })
    },
    formatChildRow (cardRow) {
      let childList = cardRow['awaitShipmentItemVoList']
      if (childList.length) {
        const { orderProcessType, processPlantSupplierName } = cardRow
        childList.forEach((item, index) => {
          childList[index] = { ...item, ...{ orderProcessType, processPlantSupplierName } }
        })
      }
    },
    openPriceAuditDialog () {
      let { deliveriedOrders, isWaitAuditPriceRecheckOrders, skuRows } = this.prevDataHandler()
      if (this.btnHandleValidate('doPriceReview', isWaitAuditPriceRecheckOrders, deliveriedOrders)) {
        this.$refs.priceReview.openDialog(skuRows)
      }
    },
    exportPdf () {
      this.loading = true
      const pageSize = this.page.pageSize
      const pageIndex = this.page.pageIndex
      const obj = this.generateParams(pageSize, pageIndex)
      const params = {
        ...obj
      }
      OemGoodsAPI.exptawaitshipmentslist(params).then(res => {
        if (res.success === false) return
        const csvData = new Blob([res], { type: 'text/csv;charset=utf-8;' })
        const csvURL = window.URL.createObjectURL(csvData)
        const tempLink = document.createElement('a')
        tempLink.href = csvURL
        tempLink.setAttribute('download', '待发货列表.csv')
        tempLink.click()
      }).finally(() => {
        this.loading = false
      })
    },
    // 操作按钮前的数据预处理
    prevDataHandler () {
      let deliveryParams = []
      let deliveriedOrders = []
      let isWaitAuditPriceRecheckOrders = []
      let skuRows = Object.keys(this.selectionsTree).reduce((acc, key) => {
        let curSelections = this.selectionsTree[key]

        if (curSelections.length > 0) {
          let row = this.tableData.find(item => String(item.id) === String(key))
          if (row) {
            // 存储已经发过货的订单号
            if (![0, 1].includes(row.status)) {
              deliveriedOrders.push(row.purchaseOrderNumber)
            }
            // 存储待处理成本复核的订单号
            if (row.isWaitAuditPriceRecheck) {
              isWaitAuditPriceRecheckOrders.push(row.purchaseOrderNumber)
            }
          }

          let arr = curSelections.map(item => {
            // 存储传入发货弹窗的数据
            deliveryParams.push({
              purchaseOrderId: item.purchaseOrderId,
              purchaseOrderItemId: item.id
            })

            return {
              purchaseOrderNumber: row.purchaseOrderNumber,
              id: item.id,
              skuCode: item.skuCode,
              skuImage: item.skuImage,
              name: item.name,
              attributesName: item.attributesName,
              originPrice: item.realPrice
            }
          })
          acc = acc.concat(arr)
        }
        return acc
      }, [])
      return {
        deliveryParams,
        deliveriedOrders,
        isWaitAuditPriceRecheckOrders,
        skuRows
      }
    },
    // 操作按钮前的校验
    btnHandleValidate (type, isWaitAuditPriceRecheckOrders, deliveriedOrders) {
      let msg0 = `订单号：${isWaitAuditPriceRecheckOrders.join()} 存在待处理成本复核,不能操作 !`
      let msg1 = `订单号：${deliveriedOrders.join()} 已发货,不能申请成本复核 !`
      if (type === 'doDelivery') { // 发货
        return isWaitAuditPriceRecheckOrders.length ? !!this.showMessageTip(msg0) : true
      }

      if (type === 'doPriceReview') { // 成本复核
        return isWaitAuditPriceRecheckOrders.length ? !!this.showMessageTip(msg0) : deliveriedOrders.length ? !!this.showMessageTip(msg1) : true
      }
      return true
    },
    showMessageTip (msg) {
      this.btnMessageTip = msg
      this.btnMessageVisible = true
    },
    priceReviewSubmit () {
      this.gotoPage(this.page.pageSize)
    }
  }
}
</script>

<style lang="scss" scoped>
.purple-tag {
  color: purple;
  border-color: purple;
}
.success-tag {
  border-color: #c2e7b0;
  color: #67c23a;
}
</style>
